body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',Helvetica,Lucida Grande,Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px!important;
  height: 6px!important;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.2);
}
::-webkit-scrollbar-track {
  background: hsla(0,0%,100%,.1);
}